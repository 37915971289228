import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../../reducers";
import * as actions from "../../../../../actions";
import {
  ButtonColors,
  ButtonTypes,
  Button,
  ButtonStyles,
  Input,
} from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import memoize from "memoize-one";
import { EValidationTestType } from "../../../../../utils/validationConfigs";
import { ValidationMessage } from "../../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../../utils/validationTools";
import {
  calculateAgeFromDates,
  parseToApiDate,
} from "../../../../../utils/react";

type Props = {
  formConfig: any;
  getValues: any;
  setValue: any;
  setFormConfig: any;
  setStep: any;
  initialData: any;
  closeModal: any;
  submitAction: any;
  employers: any;
  entities: any;
  showValidations: boolean;
};

function Termination(props: Props) {
  const { setValue, getValues, setError, control } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        noticeGivenBy: props.initialData.employee.noticeGivenBy || "4",
        checkInsuranceContinuation:
          props.initialData.employee.checkInsuranceContinuation || false,
      },
      requiredFields: {},
    },
  });

  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const continuedInsuranceAge =
    props.employers[props.initialData.employee.employerId]?.attributes
      ?.continuedInsuranceAge || 58;

  const prepareNoticeGivenByOptions = memoize(() => {
    const options = [];

    const noticeGivenByCodes = {
      4: "keine Angabe",
      1: "Arbeitnehmer",
      2: "Arbeitgeber",
      3: "Einvernehmlich",
    };
    Object.keys(noticeGivenByCodes).forEach((noticeGivenByCodeKey) => {
      options.push({
        label: noticeGivenByCodes[noticeGivenByCodeKey],
        id: noticeGivenByCodeKey,
        value: noticeGivenByCodeKey,
        validationMessageConfig: null,
        validationTests: [EValidationTestType.hasValue],
        type: "radio",
        name: "noticeGivenBy",
        inputWrapperClass: "w-100 high",
        onChange: handleNoticeGivenByRadioChange,
      });
    });

    return [options[3], options[0], options[1], options[2]];
  });

  const handleNoticeGivenByRadioChange = (name, value) => {
    setValue(`employee.noticeGivenBy`, value);
    setValue(
      "employee.checkInsuranceContinuation",
      value === "2" ? true : false
    );
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("employee"),
      "formLeaving",
      null,
      null
    );
    setError(`employee.noticeGivenBy`, validation);
    requiredFieldsTermination();
  };

  const requiredFieldsTermination = useCallback(() => {
    const requiredFieldsTermination = {};
    Object.keys({
      noticeGivenBy: "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredFieldsTermination[key] = !!getValues("employee")[key])
    );
    setValue("requiredFields", requiredFieldsTermination);
  }, [props.formConfig.formCustomization, getValues, setValue]);

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsTermination();
    }
  }, [props.formConfig.formCustomization, requiredFieldsTermination]);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  return (
    <form className="form">
      <fieldset>
        <div className="row">
          <div className="col-12 col-sm-12 labelContent">
            <h1>
              <FormattedMessage
                id="app.forms.formLeaving.termination.label"
                defaultMessage="Wer hat gekündigt?"
              />
            </h1>
            <Controller
              control={control}
              name="employee.noticeGivenBy"
              render={({ field: { value } }) => (
                <Input
                  inputWrapperClass="radio-input"
                  id={"applicationMade"}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "applicationMade"
                    ]?.hidden
                  }
                  name={"noticeGivenBy"}
                  radioOptions={prepareNoticeGivenByOptions()}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: "",
                      type: null,
                    },
                  }}
                  forcedValue={value.toString()}
                />
              )}
            />
          </div>

          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-md-6">
            <div className="modal-buttons">
              <Button
                onClick={() => props.setStep("start")}
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
              >
                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
              </Button>
              <Button
                submit={true}
                type={ButtonTypes.Standard}
                color={ButtonColors.Action}
                style={ButtonStyles.Primary}
                isDisabled={isDisabledButton}
                onClick={(e) => {
                  let hasLimitedAgeOrAbove = false;
                  if (
                    calculateAgeFromDates(
                      parseToApiDate(
                        props.getValues(
                          "insurantDataPatch.employee.quittingDate"
                        )
                      ),
                      props.getValues("insurantDataPatch.person.birthDate")
                    ) >= continuedInsuranceAge
                  ) {
                    hasLimitedAgeOrAbove = true;
                  }
                  props.setValue("insurantDataPatch.employee", {
                    ...props.getValues("insurantDataPatch.employee"),
                    noticeGivenBy: getValues("employee.noticeGivenBy"),
                    checkInsuranceContinuation: getValues(
                      "employee.checkInsuranceContinuation"
                    ),
                    employeeCustomizable1: hasLimitedAgeOrAbove,
                  });
                  props.submitAction(e);
                }}
              >
                <FormattedMessage id="app.submit" defaultMessage="" />
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Termination);
