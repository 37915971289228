import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../../reducers";
import * as actions from "../../../../../actions";
import {
  ButtonColors,
  ButtonTypes,
  Button,
  ButtonStyles,
  Input,
} from "icr-shared-components";
import { FormattedMessage } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import memoize from "memoize-one";
import { EValidationTestType } from "../../../../../utils/validationConfigs";
import { ValidationMessage } from "../../../../../@types/forms/agp/formValidation";
import { validateInputMessage } from "../../../../../utils/validationTools";

type Props = {
  formConfig: any;
  getValues: any;
  setValue: any;
  setFormConfig: any;
  setStep: any;
  closeModal: any;
  submitAction: any;
  employers: any;
  entities: any;
  showValidations: boolean;
};

function DiseaseStep2(props: Props) {
  const {
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      employee: {
        invalidationApplicationFiled: "",
      },
      requiredFields: {},
    },
  });

  const [isDisabledButton, setIsDisabledButton] = useState(true);

  const prepareExitReasonOptions = memoize((entities) => {
    const options = [];
    if (
      entities &&
      entities.ReasonsForLeaving &&
      Object.keys(entities.ReasonsForLeaving.values).length
    ) {
      const leavingCodes = {
        0: "Ja",
        1: "Nein",
      };
      Object.keys(leavingCodes).forEach((leavingCodeKey) => {
        options.push({
          label: leavingCodes[leavingCodeKey],
          id: leavingCodeKey,
          value: leavingCodeKey,
          validationMessageConfig: null,
          validationTests: [EValidationTestType.hasValue],
          type: "radio",
          name: "reasonForLeaving",
          inputWrapperClass: "w-100 high",
          onChange: handleExitReasonRadioChange,
        });
      });
    }
    return options;
  });

  const handleExitReasonRadioChange = (name, value) => {
    setValue(`employee.invalidationApplicationFiled`, value);
    const validation: ValidationMessage = validateInputMessage(
      props.formConfig.formCustomization,
      getValues("employee"),
      "formLeaving",
      null,
      null
    );
    setError(`employee.invalidationApplicationFiled`, validation);
    requiredFieldsDiseaseStep2();
  };

  const requiredFieldsDiseaseStep2 = useCallback(() => {
    const requiredFieldsDiseaseStep2 = {};
    Object.keys({
      invalidationApplicationFiled:
        props.getValues("initialData.employee.invalidationApplicationFiled") ||
        "",
    }).map(
      (key) =>
        props.formConfig.formCustomization.components[key]?.required &&
        (requiredFieldsDiseaseStep2[key] = !!getValues("employee")[key])
    );
    setValue("requiredFields", requiredFieldsDiseaseStep2);
  }, [props.formConfig.formCustomization, getValues, setValue]);

  useEffect(() => {
    if (Object.keys(props.formConfig.formCustomization.components).length > 0) {
      requiredFieldsDiseaseStep2();
    }
  }, [props.formConfig.formCustomization, requiredFieldsDiseaseStep2]);

  useEffect(() => {
    setIsDisabledButton(
      !Object.keys(getValues("requiredFields")).every(
        (key) => getValues("requiredFields")[key]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("requiredFields")]);

  return (
    <form className="form">
      <fieldset>
        <div className="row">
          <div className="col-12 col-sm-6 labelContent">
            <h1>
              <FormattedMessage
                id="app.forms.formLeaving.disease.label"
                defaultMessage="Wurde bereits ein IV-Antrag gemacht?"
              />
            </h1>
            <Controller
              control={control}
              name="employee.invalidationApplicationFiled"
              render={({ field: { value } }) => (
                <Input
                  inputWrapperClass="radio-input"
                  id={"invalidationApplicationFiled"}
                  hidden={
                    props.formConfig.formCustomization.components[
                      "invalidationApplicationFiled"
                    ]?.hidden
                  }
                  name={"reasonForLeaving"}
                  radioOptions={prepareExitReasonOptions(props.entities)}
                  validationMessageConfig={{
                    visible: props.showValidations,
                    config: {
                      message: "",
                      type: null,
                    },
                  }}
                  forcedValue={value.toString()}
                />
              )}
            />
          </div>

          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-sm-6"></div>
          <div className="col-12 col-md-6">
            <div className="modal-buttons">
              <Button
                onClick={() => props.setStep("disease")}
                type={ButtonTypes.Text}
                color={ButtonColors.Action}
              >
                <FormattedMessage id="app.cancel" defaultMessage="Abbrechen" />
              </Button>
              <Button
                submit={true}
                type={ButtonTypes.Standard}
                color={ButtonColors.Action}
                style={ButtonStyles.Primary}
                isDisabled={isDisabledButton}
                onClick={(e) => {
                  props.setValue("insurantDataPatch.employee", {
                    ...props.getValues("insurantDataPatch.employee"),
                    invalidationApplicationFiled: getValues(
                      "employee.invalidationApplicationFiled"
                    ),
                  });
                  props.submitAction(e);
                }}
              >
                <FormattedMessage id="app.submit" defaultMessage="" />
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    employers: state.app.employers,
    entities: state.app.entities,
    isLoading: state.app.loading,
    insurantsObj: state.insurants.insurantsObj,
    showValidations: state.app.showValidations,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseStep2);
